import * as React from "react";
import { FAQItem } from "../components/FAQItem";
import { Header } from "../components/Header";
import { Layout } from "../components/Layout";

const subtitle = <p><strong>Reviti</strong> Limited website terms of use</p>
// markup
const IndexPage = () => {
  return (
    <Layout title={"Terms of Use - Reviti"}>
           <Header title={"Terms of Use"} subtitle={subtitle} currentPage={"Terms of Use"}/>
           <div className="container-padding relative flex flex-col md:flex-row my-30 terms-container"> 
<div className={"w-full md:w-2/3 flex flex-col"}>


           
      <p>
        <span className="article-section-heading">
          <strong>Reviti</strong> Limited website terms of use
        </span>
      </p>
      <p>
        You have been guided here in connection with your use of an internet
        site that is operated by <strong>Reviti</strong> Limited. That company
        ("<strong>Reviti</strong>", “we”, “us”, “our”) has identified itself to
        you, and given its name and contact details, in the site in connection
        with which you have been guided here (we refer to that site as “this”
        site).
      </p>
      <p>
        This document describes how we offer the site for your use. By using
        this site, you accept these terms.
      </p>
      <p> </p>
      <p>
        <span className="article-section-heading">
          <strong>Reviti</strong> affiliates
        </span>
      </p>
      <p>
        <strong>Reviti</strong> International Sarl, is our parent company. We
        are thus a “<strong>Reviti</strong> affiliate”. Similarly, each other
        company that is directly or indirectly controlled by{" "}
        <strong>Reviti</strong> International Sarl is also a “
        <strong>Reviti</strong> affiliate”. Philip Morris International Inc. is
        our ultimate parent company.
      </p>
      <p> </p>
      <p>
        <span className="article-section-heading">Links</span>
      </p>
      <p>
        This site may contain links to other sites: some are operated by{" "}
        <strong>Reviti</strong> affiliates; others by third parties. The links
        may expose you to content that you may find offensive or objectionable.
        We provide the links solely for your convenience: we are not responsible
        for those sites (for example, for their use, content, products or
        services). Those sites will have their own terms of use, which we
        suggest you review if you visit any. You may not frame, link or
        deep-link this site to any other without our prior written consent. If
        you wish to ask us about this, see our contact details below.
      </p>
      <p> </p>
      <p>
        <span className="article-section-heading">About this site</span>
      </p>
      <p>
        We operate this site for advertising and marketing purposes to promote
        and arrange life insurance. We also provide general information about
        ourselves, our affiliates and other features we are able to offer, or
        introduce to, <strong>Reviti</strong> customers.
      </p>
      <p> </p>
      <p>
        <span className="article-section-heading">
          Accuracy, completeness and timeliness of information on this site
        </span>
      </p>
      <p>
        To the extent that material on this site describes company reports,
        performance, or company positions or views, we provide it only for
        general information. We do not promise that it is accurate, complete, or
        current. You should not rely upon it or use it as the basis for making
        significant decisions. You should consult primary, more accurate, more
        complete, and more current sources of information. This site may contain
        certain historical information. Historical information is not
        necessarily current and we provide it for your reference only. We
        reserve the right to modify this site at any time, but we have no
        obligation to update it. It is your responsibility to monitor any such
        changes.
      </p>
      <p>
        We provide financial information on this site, which we provide only for
        general information and it does not constitute financial advice. You
        should therefore not rely on any financial information provided on this
        site as financial advice or a recommendation that a policy is
        appropriate for your circumstances. You should consult a financial
        advisor if you need financial advice.
      </p>
      <p> </p>
      <p>
        <span className="article-section-heading">
          Use of material from this site
        </span>
      </p>
      <p>
        This site (including all its contents) is our property, or the property
        of our licensors. It is protected by copyright, trademark and other laws
        of the UK, USA, Switzerland and other countries. We make it available to
        you to only to browse through it and download and print copies of
        material on it, and this only for your personal, non-public,
        non-commercial use, and provided you do not remove any notices (such as
        copyright notices) on the materials. You may not otherwise use any
        material on this site. In particular, you may not modify or distribute
        any such material (including making it available on-line, selling,
        transmitting, copying or displaying it).
      </p>
      <p> </p>
      <p>
        <span className="article-section-heading">Trademarks</span>
      </p>
      <p>
        This site features logos and other trademarks and service marks that are
        our property, or the property of our licensors. This site may also
        include trademarks or service marks of third parties. All these
        trademarks are the property of their respective owners. You may not use
        or display them unless you first obtain the written permission of the
        trademark owner.
      </p>
      <p> </p>
      <p>
        <span className="article-section-heading">
          Intellectual property rights of others
        </span>
      </p>
      <p>
        We respect the intellectual property rights of others, and we request
        that our visitors do the same. If you think that someone has copied your
        work in a manner that constitutes copyright infringement, please contact
        us at the address provided below.
      </p>
      <p> </p>
      <p>
        <span className="article-section-heading">Uploading content</span>
      </p>
      <p>
        When you use this site to upload content (“<strong>Uploaded</strong> 
        <strong>Content</strong>”), or contact other users, you must comply with
        our Acceptable Use Policy.
      </p>
      <p>
        We will treat Uploaded Content as non-confidential and non-proprietary.
        You grant us an irrevocable, non-exclusive, perpetual, royalty-free,
        sublicensable, transferable right to use Uploaded Content (including
        downloading, copying, and storing) for the purpose of providing the
        site.
      </p>
      <p>
        We accept no liability for loss of Uploaded Content or for any
        subsequent distribution or use. You may request us to remove Uploaded
        Content from the site, but you accept that this will not always be
        possible or practicable, particularly where third party-controlled media
        are involved.
      </p>
      <p> </p>
      <p>
        <span className="article-section-heading">
          Cookies and similar technologies
        </span>
      </p>
      <p>
        To the extent that in providing the site we use cookies and similar
        technologies, we do so as described in our Cookie preferences.
      </p>
      <p> </p>
      <p>
        <span className="article-section-heading">Personal information</span>
      </p>
      <p>
        To the extent that in providing the site we collect and use any personal
        information about you, we do so as described in our{" "}
        <a
          href="/{localLink:umb://document/cc407bb483ba458498dbe9509a884bc7}"
          title="Privacy Notice"
          data-anchor="#"
        >
          Privacy Notice
        </a>
        .
      </p>
      <p> </p>
      <p>
        <span className="article-section-heading">Your login details</span>
      </p>
      <p>
        If you choose, or you are provided with, a user identification code,
        password or any other piece of information as part of our security
        procedures, you must treat that information as confidential. You must
        not disclose it to any third party.
      </p>
      <p>
        We have the right to disable any user accounts at any time if, in our
        reasonable opinion, you have failed to comply with any of the provisions
        of these Terms of Use and/or if we believe that your account is being
        used in an unauthorised or fraudulent manner.
      </p>
      <p>
        If you know or suspect that anyone other than you has or is likely to
        access your user account, you must promptly notify us. Following such
        notification, you may be required to set up a new account with a new
        email address.
      </p>
      <p> </p>
      <p>
        <span className="article-section-heading">Technical limits</span>
      </p>
      <p>
        Although we do our best to make the site informative, we provide it on
        an “as is” and “as available” basis. We also provide the site only for
        domestic, private use. It is solely your responsibility to ensure that
        it is suitable for your purposes.
      </p>
      <p>
        We reserve the right to restrict or terminate your access to the site or
        any part of it at any time.
      </p>
      <p>
        If you download any material from this site, or follow any links from
        it, you do so at your own discretion and risk. You will be solely
        responsible for any damage to your computer system or loss of data that
        results from the download of any such material.
      </p>
      <p>
        If any <strong>Reviti</strong> affiliate gives you advice or
        information, whether oral or written, through or from the site, this
        will not constitute a warranty. The operation of the site depends upon
        the effective functioning of third party equipment and services (for
        example, the internet, your device, your web browser, and so on). We do
        not guarantee these and will not be liable for them.
      </p>
      <p>
        If you cause a technical disruption of the site or of the systems
        transmitting it, you will be responsible for all losses, liabilities,
        damages, costs and expenses (including reasonable attorneys’ fees and
        court costs) arising from that disruption.
      </p>
      <p> </p>
      <p>
        <span className="article-section-heading">Limit of liability</span>
      </p>
      <p>
        We do not exclude or limit our liability for matters in respect of which
        the law does not permit us to do so. Typically, these matters include
        liability for death or personal injury caused by our negligence, for
        fraudulent misrepresentation, or for gross negligence.
      </p>
      <p>
        We accept no liability as to the suitability, satisfactory quality or
        fitness of the site in meeting your needs and we exclude all express or
        implied promises, warranties, conditions or guarantees, including:
      </p>
      <ul>
        <li>that access to the site will be uninterrupted or error-free;</li>
        <li>
          that the site or the computer server from which it is made available
          are free of viruses or other harmful components; and
        </li>
        <li>
          in relation to the accuracy, content, timeliness, completeness,
          reliability, quality or suitability of any content the site makes
          available.
        </li>
      </ul>
      <p>
        We will not be liable to you for any loss or damage (for example, to
        your computer hardware, software or data) caused by technologically
        harmful material (for example, a virus, or a distributed
        denial-of-service attack) in connection with your use of our site
        (including accessing any content, site or app on or via it).
      </p>
      <p>
        Further, we will not be liable to you for any special, indirect,
        punitive or consequential losses or damages, or any loss of data,
        profits, sales, revenues, business, business opportunity, revenue,
        reputation or goodwill.
      </p>
      <p> </p>
      <p>
        <span className="article-section-heading">Jurisdiction</span>
      </p>
      <p>
        The laws of England and Wales govern these Terms of Use and your use of
        the site. It is technically possible for you to obtain access to this
        site from any country in the world, and we have no practical ability to
        prevent such access. We designed this site to comply with the laws of
        England and Wales. <strong>Reviti</strong> affiliates operate in various
        countries throughout the world and comply with the laws of the countries
        in which they operate. If any material on this site, or your use of it,
        is contrary to the laws where you are, we do not intend you to use the
        site, and we ask you not to use it.
      </p>
      <p> </p>
      <p>
        <span className="article-section-heading">
          Updates to these Terms of Use
        </span>
      </p>
      <p>
        We reserve the right, at our complete discretion, to change these terms
        and conditions at any time by posting revised terms on the site. It is
        your responsibility to check periodically for any changes we may make to
        these terms and conditions. Your continued use of this site following
        the posting of changes to these terms or other policies means you accept
        the changes.
      </p>
      <p> </p>
      <p>
        <span className="article-section-heading">How to contact us</span>
      </p>
      <p>
        If you have any questions or concerns, or wish to exercise any of your
        rights, please communicate with us through our Contact Us page (we ask
        for your e-mail address and name in order to reply to your message) or
        write to us at: <strong>Reviti</strong> Limited, One New Change, 4th
        Floor, London, EC4M 9AF, United Kingdom.
      </p>
      </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
